import React, { Component } from 'react';
import styled from 'styled-components';

const ProjectStyle = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1.5px solid
    ${props =>
    props.i % 2 === 1
      ? props.theme.secondaryColor
      : props.theme.linkColor};
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5rem;
  }
  .title {
    font-weight: 700;
  }
  .date {
    margin: auto 0;
    font-weight: 400;
    font-style: italic;
    font-size: 2rem;
  }
  hr {
    border: 0;
    border-top: 1.5px solid
      ${props => props.i % 2 === 1
    ? props.theme.secondaryColor
    : props.theme.linkColor};
  }
  li,
  .tools,
  .links {
    padding: 1rem;
    font-size: 1.9rem;
    font-weight: 400;
  }
  .notes {
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    font-style: italic;
  }
  img {
    max-width: 30rem;
    max-height: 30rem;
    height: auto;
    margin-right: 1.5rem;
  }

  @media (max-width: ${props => props.theme.smallWidth}) {
    .title,
    .date {
      min-width: 100%;
      text-align: center;
    }
    img {
      max-width: 15rem;
      max-height: 15rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
    .links {
      text-align: center;
    }
  }
`;
class Project extends Component {
  formatDate(str) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    // const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    // ];
    let [m, y] = str.split('/');
    return [monthNames[parseInt(m) - 1], y];
  }
  formatProjectDates(date) {
    const { start, end } = date;
    const [m1, y1] = this.formatDate(start);
    const [m2, y2] = this.formatDate(end);
    if (start === end) {
      return `${m1} ${y1}`;
    }
    if (y1 === y2) {
      return `${m1} – ${m2} ${y1}`;
    }
    return `${m1} ${y1} – ${m2} ${y2}`;
  }

  render() {
    return (
      <ProjectStyle i={this.props.index}>
        <div className="top">
          <span className="title">{this.props.title}</span>
          {this.props.date && (
            <span className="date">
              {this.formatProjectDates(this.props.date)}
            </span>
          )}
        </div>

        <hr />
        <ul style={{ paddingLeft: '1.5em', paddingRight: '1.5em' }}>
          {this.props.texts.map(text => (
            <li key={text}>{text}</li>
          ))}
          <p className="tools">
            {this.props.tools && this.props.tools.join(' | ')}
          </p>
          {this.props.notes &&
            this.props.notes.map((note, index) => (
              <p className="notes" key={index}>
                {note}
              </p>
            ))}
          <p className="links">
            {this.props.links &&
              this.props.links
                .map(({ name, url }, index) => (
                  <a
                    href={url}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name || url}
                  </a>
                ))
                .reduce((prev, curr) => [prev, ' | ', curr])}
          </p>
          <p className="links">
            {this.props.images &&
              this.props.images.map((image, index) => (
                <a
                  href={image}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={image}
                    key={index}
                    alt={`${this.props.title} - ${index + 1}`}
                  />
                </a>
              ))}
          </p>
        </ul>
      </ProjectStyle>
    );
  }
}

export default Project;
