export const projects = [
  {
    title: 'Arts and Computing Course Project',
    date: { start: '02/2016', end: '05/2016' },
    texts: [
      'Developed an android application which aims to match people from opposite genders by checking their harmony on a cards against humanity game.',
      'It was written with a group of 3 people. Android client was written with Java, backend server was written with PHP.'
    ],
    tools: ['Java', 'Android SDK', 'PHP', 'MySQL'],
    links: [
      {
        name: 'Project Website',
        url: 'http://ecemozkara93.wixsite.com/mateapp'
      }
    ]
  },
  {
    title: 'Software Engineering Course Project',
    date: { start: '01/2017', end: '05/2017' },
    texts: [
      'Developed an event ticket management software with a group of five people.',
      'Wrote server with Java and Spring Boot framework. Web client was written with Angular.js, Android client written with Java.'
    ],
    tools: [
      'Java',
      'Spring Boot',
      'Android SDK',
      'Javascript',
      'Angular.js',
      'MySQL'
    ],
    links: [
      {
        name: 'Android Client',
        url:
          'https://github.com/ebozkurt93/CS308-Ticketing-System-Android-Client'
      },
      {
        name: 'Web Client',
        url: 'https://github.com/ebozkurt93/CS-308-Ticketing-Front-end'
      },
      {
        name: 'Backend',
        url: 'https://github.com/ebozkurt93/CS-308-Ticketing-Backend'
      }
    ]
  },
  {
    title: 'Computer and Network Security Course Project',
    date: { start: '03/2017', end: '04/2017' },
    texts: [
      'Developed a secure file transfer application.',
      'Created application had 3 modules: Authentication server which was responsible for authenticating clients, file server which stores and distributes files in a secure way, and client module which gets authenticated to the authentication server and connects to file server to upload and download files in a secure way.'
    ],
    tools: ['C#', 'C# GUI Application'],
    links: [
      {
        name: 'Repository',
        url: 'https://github.com/ebozkurt93/CS-432-Project'
      }
    ]
  },
  {
    title: 'Favor – Social Media Application',
    date: { start: '03/2017', end: '12/2017' },
    texts: [
      'Developed a social media application as a hobby project. Aim of the app was matching people who need favors with people who can help them on those favors.',
      'Wrote server with Java and Spring Boot framework. Android client was written with Java.'
    ],
    tools: ['Java', 'Spring Boot', 'Android SDK', 'MySQL'],
    links: [
      {
        name: 'Android Client',
        url: 'https://github.com/ebozkurt93/Favor-Android-App'
      },
      {
        name: 'Backend',
        url: 'https://github.com/ebozkurt93/Favor-App-Backend-API'
      }
    ],
    images: [
      'https://i.postimg.cc/Jnd8Wwkq/Screenshot-1511706660.png',
      'https://i.postimg.cc/nrjJFdKt/Screenshot-1511706669.png',
      'https://i.postimg.cc/P5GtxkFV/Screenshot-1511706708.png',
      'https://i.postimg.cc/3Rwh6rdN/Screenshot-1511706867.png',
      'https://i.postimg.cc/tCnqpgDV/Screenshot-1511706872.png',
      'https://i.postimg.cc/KYfFJ3cC/Screenshot-1511706955.png',
      'https://i.postimg.cc/HkQT8PqY/Screenshot-1511706965.png',
      'https://i.postimg.cc/7hfxCPkd/Screenshot-1511706897.png',
      'https://i.postimg.cc/j2mRyVq5/Screenshot-1511706892.png',
      'https://i.postimg.cc/fWrzGd7N/Screenshot-1511706925.png',
      'https://i.postimg.cc/hGccL1n9/Screenshot-1511706982.png'
    ]
  },
  {
    title: 'Machine Learning Course Project – Tweet Sentiment Analysis',
    date: { start: '12/2017', end: '12/2017' },
    texts: [
      'Developed multiple machine learning systems which can take a tweet targeted to a local bank in Turkey and predict positivity/negativity of the content. It was written with a group of 4 people.'
    ],
    tools: ['Python', 'Numpy', 'Scikit-learn'],
    links: [
      {
        name: 'Repository',
        url:
          'https://github.com/ebozkurt93/CS-412-Tweet-Sentiment-Analysis-Project'
      }
    ]
  },
  {
    title:
      'Cryptography Course Project – Implementing Cryptocurrency with Blockchains',
    date: { start: '12/2017', end: '12/2017' },
    texts: [
      'Developed a cryptocurrency using blockchain by creating software for proof of work and digital signatures.'
    ],
    tools: ['Python'],
    links: [
      {
        name: 'Repository',
        url: 'https://github.com/ebozkurt93/CS-411-Blockchain-Project'
      }
    ]
  },

  {
    title: 'Data Science Course Project – Yelp Dataset Analysis',
    date: { start: '02/2018', end: '06/2018' },
    texts: [
      'Did research on different user/business patterns in a two people team.',
      'Were able to predict review ratings with over 90% accuracy using multiple Machine Learning systems on a publicly accessible Kaggle dataset.'
    ],
    tools: [
      'Python',
      'Jupyter Notebook',
      'Pandas',
      'Numpy',
      'Matplotlib',
      'Scikit-learn'
    ],
    links: [
      { name: 'Blog', url: 'https://yelpdataset.wordpress.com/' },
      { name: 'Repository', url: 'https://github.com/ebozkurt93/cs210-yelp' }
    ]
  },
  {
    title: 'Using Machine Learning for Automated Bug Triaging',
    date: { start: '11/2017', end: '05/2018' },
    texts: [
      'Worked in a team of four people to do research and create a system for automated bug triaging with the support of Siemens employees Serter Bekler and Yasin Kadakal.',
      'Developed a tool which does component prediction, severity prediction and duplicate bug detection with more than 75% accuracy on open source bug repositories and is being used by Siemens. (Mozilla public bug repository, used last ten years bugs [950.000 bugs])'
    ],
    tools: [
      'Python',
      'Jupyter Notebook',
      'Pandas',
      'Numpy',
      'Matplotlib',
      'Scikit-learn'
    ],
    notes: [
      'Due to the nature of the project I cannot share the entire system here, but I can share the crawler we wrote to get bug data from Bugzilla'
    ],
    links: [
      {
        name: 'Bugzilla Bug Crawler',
        url: 'https://github.com/ebozkurt93/Bugzilla-Bug-Crawler'
      }
    ]
  },
  {
    title: 'Cpaths Grading System',
    date: { start: '11/2018', end: '03/2019' },
    texts: [
      'Wrote a web application to simplify financial assistance process for a nonprofit organization. With this application applicants can apply and juries can vote on who to financially support to in a seamless manner.',
      'Web client was written with React.js using Next.js framework, and backend is written with Node.js.'
    ],
    tools: [
      'Next.js',
      'React.js',
      'Apollo Client',
      'GraphQL Yoga Server',
      'Prisma ORM',
      'MySQL',
      'Docker',
      'Docker Compose'
    ],
    links: [
      {
        name: 'Repository',
        url: 'https://github.com/ebozkurt93/cpaths-grading-system'
      }
    ]
  },
  {
    title: 'Regular Expressions Presentation',
    date: { start: '02/2020', end: '03/2020' },
    texts: [
      'Created an interactive website/presentation to teach basics of regular expressions.'
    ],
    tools: [
      'React.js',
      'mdx-deck'
    ],
    links: [
      {
        name: 'Website',
        url: 'https://regex-presentation.erdem-bozkurt.com'
      },
      {
        name: 'Repository',
        url: 'https://github.com/ebozkurt93/regex-presentation'
      }
    ]
  }
];
