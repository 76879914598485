import React, { useState } from 'react';
import Helmet from 'react-helmet';
import Page from '../components/Page';
import Header from '../components/Header';
import Content from '../components/styles/Content';
import PageTitle from '../components/styles/PageTitle';
import { Link } from 'gatsby';
import Project from '../components/Project';
import styled from 'styled-components';
import { projects as data } from '../data';

function ProjectsPage() {
  const [reverse, setReverse] = useState(true);
  const ProjectsDiv = styled.div`
    padding-top: 2rem;
    > * {
      margin-top: 2rem;
    }
  `;

  return (
    <Page>
      <>
        <Helmet title="Projects" />
        <Header projects />
        <Content>
          <PageTitle>Projects</PageTitle>
          <p>
            Here are some of the projects that I've worked on in the past, sorted in
            <span className="link_like" onClick={() => setReverse(!reverse)}>
              {reverse ? ' reverse' : ' '} chronological
            </span>{' '}
            order. For several reasons I'm not adding many projects I've worked in recently to here, but if you want to get information about those or what I'm working on currently, feel free to{' '}
            <Link to="/contact">contact me.</Link>
          </p>
          <div />
          <ProjectsDiv>
            {(reverse ? [...data].reverse() : data).map((project, index) => (
              <Project key={index} index={index} {...project} />
            ))}
          </ProjectsDiv>
        </Content>
      </>
    </Page>
  );
}

export default ProjectsPage;
