import styled from 'styled-components';

const H2 = styled.h2`
  display: inline-block;
  position: relative;
  font-size: 3.6rem;
  margin-bottom: 2rem;
  // done to prevent this popping over header blur
  z-index: -1;
  &:before {
    border-bottom: 2px solid;
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 2rem);
    bottom: 1rem;
  }
  @media (max-width: ${props => props.theme.smallWidth}) {
    display: flex;
    flex-direction: column;
    text-align: center;
    &:before {
      width: 0;
    }
  }
`;

export default H2;
