import styled from 'styled-components';

const Content = styled.div`
  margin: 5rem 10rem;
  font-size: 2.4rem;
  margin-top: 5rem;
  @media (max-width: ${props => props.theme.smallWidth}) {
    margin: 3rem 2rem;
    font-size: 2rem;
  }
`;

export default Content;
