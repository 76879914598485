import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { ThemeContext } from './Page';
import Moon from '../../resources/icons/moon';
import Sun from '../../resources/icons/sun';

const HeaderStyle = styled.div`
  border-bottom: 1px solid ${props => props.theme.textColor};
  font-size: 2.4rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.smallWidth}) {
    flex-direction: column;
    text-align: center;
    padding-bottom: 1rem;
  }
  position: sticky;
  backdrop-filter: blur(10px);
  top: ${p => p.visible ? '0px' : '-100%'};
  transition: top 0.4s ease-in-out;
  background: transparent;

  div {
    margin: auto 0;
  }

  .main {
    font-size: 2.8rem;
    text-decoration: none;
    color: ${props => props.theme.textColor};
  }
  a {
    margin: 1rem;
  }
`;

const CustomLink = styled.span`
  position: relative;

  &:before {
    transition: width 0.4s;
    content: '';
    position: absolute;
    width: 0;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);

    border-bottom: 2px solid;
  }
  &.active {
    color: ${props => props.theme.secondaryColor};
  }
  &:hover,
  &:active {
    /* outline: none; */
    color: ${props => props.theme.secondaryColor};
    &:before {
      width: calc(80%);
    }
  }
`;

const LinkGroupStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  @media (max-width: ${props => props.theme.smallWidth}) {
    display: block;
  }
`

const ThemeIcon = styled.span`
  cursor: pointer;
  fill: ${(props) => props.theme.textColor};

  &:hover {
    transition: fill 0.4s;
    fill: ${(props) => props.theme.linkColor};
  }
  vertical-align: middle;
  padding-right: 1rem;
  padding-top: 1.25rem;
  @media (max-width: ${props => props.theme.smallWidth}) {
    padding-right: 0;
  }
`;

const Header = props => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = useCallback(() => {
    if (typeof window !== 'undefined') {
      const isScrollingUp = window.scrollY + 50 < lastScrollY;
      const isScrollingDown = window.scrollY > lastScrollY + 50;

      if (isScrollingUp) {
        setIsVisible(true);
        setLastScrollY(window.scrollY);
      } else if (isScrollingDown) {
        setIsVisible(false);
        setLastScrollY(window.scrollY);
      }
  }
}, [lastScrollY]);


  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeaderVisibility);

      return () => {
        window.removeEventListener('scroll', controlHeaderVisibility);
      };
    }
  }, [lastScrollY, controlHeaderVisibility]);


  const isActive = p => (p ? 'active' : '');

  const {theme, resetTheme, toggleTheme} = useContext(ThemeContext);

  return (
    <HeaderStyle visible={isVisible}>
      <Link className="main" to="/">
        Erdem Bozkurt
      </Link>
      <LinkGroupStyle>
        <Link to="/about">
          <CustomLink className={isActive(props.about)}>About</CustomLink>
        </Link>
        <Link to="/books">
          <CustomLink className={isActive(props.books)}>Books</CustomLink>
        </Link>
        <Link to="/contact">
          <CustomLink className={isActive(props.contact)}>Contact</CustomLink>
        </Link>

        <ThemeIcon onClick={(e) => e.shiftKey ? resetTheme() : toggleTheme()}>
          {theme === 'dark' ? <Sun /> : <Moon />}
        </ThemeIcon>
      </LinkGroupStyle>
    </HeaderStyle>
  );
};
export default Header;
